/* eslint-disable no-nested-ternary */
import React, { ComponentPropsWithRef, FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { ADVERT_FORMAT_ID } from '@adverts/business/Adverts.contants';
import { GenericAd } from '@adverts/components/GenericAd.component';
import { BreadCrumb } from '@components/BreadCrumb/BreadCrumb.component';
import { BreadCrumbStep } from '@components/BreadCrumb/BreadCrumb.types';
import { ClippedImage } from '@components/ClippedImage/ClippedImage.component';
import { Text } from '@components/Text/Text.component';
import { TextProps } from '@components/Text/Text.types';
import { Breakpoint, useClassNames, useIsBreakpointDown } from '@styles/breakpoints';
import { userSelectors } from '@user/business/User.store';
import { ClippedImageStyle, ImageSrc } from '@utils/images';
import classnames from 'classnames';
import styles from './PlcPageHeader.module.scss';

type Props = ComponentPropsWithRef<'header'> & {
  i18nTitle?: string;
  i18nTitleOptions?: {};
  i18nVariant?: TextProps['variant'];
  stringTitle?: string;
  breadcrumbSteps?: BreadCrumbStep[];
  breadCrumbSpacing?: string;
  heroImgSrc?: ImageSrc;
  withMegaBannerAd?: boolean;
  withBlockAd?: boolean;
  withFormatWideAd?: boolean;
  titleClassName?: string;
  wrapperSpecificClass?: string;
};

const PlcPageHeader: FC<PropsWithChildren<Props>> = ({
  i18nTitle,
  i18nTitleOptions,
  i18nVariant,
  stringTitle,
  breadcrumbSteps,
  breadCrumbSpacing,
  heroImgSrc,
  className,
  children,
  withBlockAd = true,
  withMegaBannerAd = true,
  withFormatWideAd = false,
  titleClassName,
  wrapperSpecificClass,
  ...rest
}) => {
  const isMobile = useIsBreakpointDown(Breakpoint.m);
  const isSmartphone = useIsBreakpointDown(Breakpoint.s);
  const isSSO = useSelector(userSelectors.isSSO);
  const rootClass = classnames(
    styles.plcPageHeader,
    'wrapper-s',
    (!breadcrumbSteps || isSSO) && 'plc-pt-basis',
    className
  );
  const titleClass = useClassNames({ all: 'plc-mb-basis', m: 'plc-mb-l' });
  const wrapperClassName = useClassNames({
    all: [wrapperSpecificClass],

    m: ['row'],
  });
  const adClassName = useClassNames({ all: 'plc-mt-m', m: ['plc-mt-0', 'plc-ml-m'] });
  const content = (
    <>
      {(i18nTitle || stringTitle) && (
        <Text
          className={titleClassName || titleClass}
          i18nKey={i18nTitle}
          i18nOptions={i18nTitleOptions}
          tag="h1"
          variant={i18nVariant ? i18nVariant : isMobile ? 'h3' : 'h2'}
        >
          {stringTitle}
        </Text>
      )}
      {children}
    </>
  );

  return (
    <>
      {isSmartphone && withMegaBannerAd && <GenericAd className={adClassName} formatId={ADVERT_FORMAT_ID.MegaBanner} />}
      {heroImgSrc && (
        <ClippedImage alt="" clipStyle={ClippedImageStyle.settings} plcSrc={{ src: heroImgSrc, type: 'local' }} />
      )}
      <header className={rootClass} {...rest}>
        {breadcrumbSteps && (
          <BreadCrumb className={breadCrumbSpacing} customSpacing={!!breadCrumbSpacing} steps={breadcrumbSteps || []} />
        )}
        {withBlockAd ? (
          <div className={wrapperClassName}>
            <div>{content}</div>
            {!isSmartphone && <GenericAd className={adClassName} formatId={ADVERT_FORMAT_ID.Block} />}
          </div>
        ) : (
          content
        )}
      </header>
    </>
  );
};

export { PlcPageHeader };
