import { ATClickInfo, PlcATClickInfo, PlcATPageInfo, TagPlanService } from './TagPlan.service';
import cookies from 'js-cookie';
import { AccountType } from 'pleinchamp-api-client/dist/src/user/user';
import { useSelector } from 'react-redux';
import { useTranslation } from '@locales/useTranslation.hook';
import { userSelectors } from '@user/business/User.store';
import { slugify } from '@utils/strings';
import { PlcCustomATInfo } from './TagPlan.types';

function getUserTypeId(accountType?: AccountType): string {
  switch (accountType) {
    // Not logged in
    case undefined:
      return '1';
    case AccountType.Free:
      return '2';
    case AccountType.Cac:
      return '3';
    case AccountType.Sec:
      return '4';
    case AccountType.BigAccount:
      return '5';
    case AccountType.Invited:
      return '8';
    default:
      return '7';
  }
}

export function useTagPlan() {
  const user = useSelector(userSelectors.getUser);

  const { t } = useTranslation(['common']);
  const category = getUserTypeId(user?.accountType);

  function buildATName(tagPageNameI18nArray: PlcCustomATInfo['tagPageNameI18nArray']): ATClickInfo['name'] {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return [
      t('common.app-name'),
      ...tagPageNameI18nArray
        .filter((s) => s !== undefined)
        .map((s) => {
          const normalizedString = slugify(
            // eslint-disable-next-line no-nested-ternary
            (typeof s === 'string' ? t(s) : s?.notI18n ? s?.code : t(s?.code)) as string
          );
          return encodeURIComponent(normalizedString);
        }),
    ].join('::');
  }

  function tagPage({ tagPageNameI18nArray, level2, ...rest }: PlcATPageInfo) {
    TagPlanService.tagPage({
      level2,
      name: buildATName(tagPageNameI18nArray),
      ...rest,
    });

    TagPlanService.tagVisitor({ category });
  }

  function tagClick({ tagPageNameI18nArray, type = 'action', level2, ...rest }: PlcATClickInfo) {
    TagPlanService.tagClick({
      level2,
      name: buildATName(tagPageNameI18nArray),
      type,
      ...rest,
    });
    TagPlanService.tagVisitor({ category });
  }

  function ATisConsent() {
    const isConsent = false;
    const ATcookie = cookies.get('atauthority');
    if (ATcookie) {
      return JSON.parse(ATcookie).val.visitor_mode !== 'no-consent';
    }
    return isConsent;
  }

  function ATsetConsent() {
    TagPlanService.ATsetConsent();
  }

  return { ATisConsent, ATsetConsent, tagClick, tagPage };
}
