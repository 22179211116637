import React, { FC } from 'react';
import { Switch as MuiSwitch, SwitchProps } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { PlcColor } from '@styles/colors';

const SWITCH_WIDTH = 52;
const SWITCH_HEIGHT = 32;
const SWITCH_BUTTON_SIZE = 20;

const SWITCH_INNER_PADDING = (SWITCH_HEIGHT - SWITCH_BUTTON_SIZE) / 2;
const SWITCH_TRANSLATE_X = SWITCH_WIDTH - (SWITCH_BUTTON_SIZE + 2 * SWITCH_INNER_PADDING);
const StyledMuiSwitch = withStyles({
  checked: {},
  focusVisible: {},
  root: {
    height: SWITCH_HEIGHT,
    padding: 0,
    width: SWITCH_WIDTH,
  },
  switchBase: {
    '&$checked': {
      '& + $track': {
        backgroundColor: PlcColor.WHITE,
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
      color: PlcColor.GREEN_LEAF,
      transform: `translateX(${SWITCH_TRANSLATE_X}px)`,
    },
    '&$focusVisible $thumb': {
      border: 'none',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    color: PlcColor.GREY_CLOUD_DARK,
    padding: SWITCH_INNER_PADDING,
  },
  thumb: {
    boxShadow: 'none',
    height: SWITCH_BUTTON_SIZE,
    width: SWITCH_BUTTON_SIZE,
  },
  track: {
    backgroundColor: PlcColor.WHITE,
    border: `1px solid ${PlcColor.GREY_CLOUD_MEDIUM}`,
    borderRadius: SWITCH_HEIGHT / 2,
    opacity: 1,
  },
})(MuiSwitch);

const Switch: FC<SwitchProps> = ({ className, ...rest }) => {
  return <StyledMuiSwitch className={className} {...rest} />;
};

export { Switch };
